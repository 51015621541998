import { navigate } from "gatsby";
import { get } from "lodash";
import moment from "moment";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { pairingLogin } from "../../../redux/actions/user-actions";
import { routes } from "../../../route";

// markup
const RequireAuthWrapper = (props) => {
  useEffect(() => {
    const user = get(props, "user");
    // console.log("requireAuth", user);

    if (get(user, "pairingLogin") === false) {
      // navigate(routes.rewardExplore.to());
      navigate(
        routes.home.to({
          pairing: "auth",
        })
      );
    } else {
      let loginTime = get(user, "pairingLoginTime");
      loginTime = moment(new Date(loginTime));
      let expiredTime = loginTime.add(30, "minutes").toDate().getTime();
      let dateNow = new Date();
      if (dateNow.getTime() > expiredTime) {
        // console.log("exceed");
        props.pairingLogin(false);
        // navigate(routes.home.to());
        navigate(
          routes.home.to({
            pairing: "auth",
          })
        );
      } else {
        //refresh
        props.pairingLogin(true);
      }
    }
  }, []);

  return <React.Fragment>{props.children}</React.Fragment>;
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  pairingLogin,
};
export default connect(mapStateToProps, mapDispatchToProps)(RequireAuthWrapper);
