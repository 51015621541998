import { isEmpty } from "@antv/util";
import { Spin } from "antd";
import { navigate } from "gatsby";
import { map, set, sumBy } from "lodash";
import get from "lodash.get";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import getPairingUser from "../../../newApi/pairing/getPairingUser";
import getPlacements from "../../../newApi/placement/getPlacements";
import { triggerModal } from "../../../redux/actions/app-actions";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import { routes } from "../../../route";
import "../../../styles/progress-bar.css";
import { formatDecimalNumber } from "../../../utilities/common-function";
import { numberDecimal } from "../../../utilities/startUp";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";
import RequirePairingAuthWrapper from "../../general/components/RequirePairingAuthWrapper";

// const PAGESIZE = 10;
// markup
const NewsAndVideo = (props) => {
  // const location = useLocation();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [capSummaryData, setCapSummaryData] = useState({});
  const [updateUser, setUpdateUser] = useState({});
  let user = get(props, "user.user");
  let accessKey = get(props.user, "accessKey");

  // console.log(props);
  // console.log(user);

  useEffect(() => {
    let profile = get(user, "profile");
    // console.log(profile);
    if (!isEmpty(profile)) {
      setLoading(true);

      getPlacements(7, 0, {
        userId: get(user, "pairingUser._id") || "",
      })
        .then((res) => {
          // console.log(res);
          let placement_data = get(res, "data");
          let staticUsed = 0;
          let staticTotal = 0;
          let dynamicUsed = 0;
          let dynamicTotal = 0;
          if (!isEmpty(placement_data)) {
            staticUsed = sumBy(placement_data, "usedStaticCap");
            staticTotal = sumBy(placement_data, "staticCap");
            dynamicUsed = sumBy(placement_data, "usedDynamicCap");
            dynamicTotal = sumBy(placement_data, "dynamicCap");
            let staticData = {
              used: staticUsed,
              total: staticTotal,
              percent: (staticUsed / staticTotal) * 100,
            };
            let dynamicData = {
              used: dynamicUsed,
              total: dynamicTotal,
              percent: (dynamicUsed / dynamicTotal) * 100,
            };
            let tempDataSource = {};
            set(tempDataSource, "staticData", staticData);
            set(tempDataSource, "dynamicData", dynamicData);
            setCapSummaryData(tempDataSource);

            // console.log(tempDataSource);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
      getVipCap();
    }
  }, []);

  function getVipCap() {
    getPairingUser(
      1,
      0,
      {
        _id: get(user, "pairingUser._id"),
      },
      accessKey
    )
      .then((res) => {
        // console.log("updated user", res);
        setUpdateUser(get(res, "data[0]"));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const capTypes = [
    {
      title: "static",
      // text: "Static",
      text: t("static", sourceKey.pairing),
      textCn: "静态",
      used: get(capSummaryData, "staticData.used") || 0,
      total: get(capSummaryData, "staticData.total") || 0,
      percentage: get(capSummaryData, "staticData.percent") || 0,
      // percentage: 90.25,
      path: routes.staticCap.to(),
      highVolumeColor: "yellow",
      lowVolumeColor: "green",
    },
    {
      title: "dynamic",
      text: t("dynamic", sourceKey.pairing),
      textCn: "动态",
      used: get(capSummaryData, "dynamicData.used") || 0,
      total: get(capSummaryData, "dynamicData.total") || 0,
      percentage: get(capSummaryData, "dynamicData.percent") || 0,
      // percentage: 64.25,
      path: routes.dynamicCap.to(),
      highVolumeColor: "red",
      lowVolumeColor: "darkblue",
    },
    // {
    //   title: "vip",
    //   text: t("vip", sourceKey.pairing),
    //   textCn: "",
    //   used: get(updateUser, "usedVipCap") || 0,
    //   total: get(updateUser, "vipCap") || 0,
    //   percentage:
    //     (get(updateUser, "usedVipCap") / get(updateUser, "vipCap")) * 100 || 0,
    //   // percentage: 80.25,
    //   path: routes.vipCap.to(),
    //   highVolumeColor: "orange",
    //   lowVolumeColor: "skyblue",
    // },
  ];
  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={loading}>
          <RequirePairingAuthWrapper>
            <NavHeader
              showBack={true}
              suffix={
                <React.Fragment>
                  <div className="justify-end flex items-center ">
                    {t("revenueCapacity", sourceKey.pairing)}
                  </div>
                </React.Fragment>
              }
            >
              <div className="pt-2">
                <div
                  className=" py-4 px-4 m-4 rounded-3xl"
                  // style={{ backgroundColor: "#29313E" }}
                >
                  {map(capTypes, (item, index) => {
                    return (
                      <div
                        className="mx-2 px-1 mb-6 rounded-2xl touchcap-bg-color flex justify-start"
                        onClick={() => {
                          navigate(get(item, "path"));
                        }}
                      >
                        <div className="mr-8">
                          <div
                            className={`${
                              get(item, "percentage") <= 50
                                ? get(item, "lowVolumeColor")
                                : get(item, "highVolumeColor")
                            } px-2  pb-4 `}
                          >
                            <div className="progress">
                              <div className="inner">
                                <div className="percent">
                                  <span>
                                    {formatDecimalNumber(
                                      get(item, "percentage"),
                                      numberDecimal
                                    )}
                                  </span>
                                  %
                                </div>
                                <div
                                  className="water"
                                  style={{
                                    top: `${
                                      100 -
                                      formatDecimalNumber(
                                        get(item, "percentage"),
                                        numberDecimal
                                      )
                                    }%`,
                                  }}
                                ></div>
                                <div className="glare"></div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className=" text-base font-semibold flex justify-center items-center">
                          <div>
                            <div>{get(item, "text")}</div>
                            <div>
                              {formatDecimalNumber(
                                get(item, "used"),
                                numberDecimal
                              )}{" "}
                              /{" "}
                              <MediaQuery maxWidth={500}>
                                <br />
                              </MediaQuery>
                              {formatDecimalNumber(
                                get(item, "total"),
                                numberDecimal
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </NavHeader>
          </RequirePairingAuthWrapper>
        </Spin>
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
  triggerModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(NewsAndVideo);
